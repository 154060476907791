/**
 * @generated SignedSource<<71ddc02b5072ec384eb0db986eb77f92>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ChainIdentifier = "AMOY" | "APE_CHAIN" | "APE_CURTIS" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BERA_CHAIN" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "FLOW" | "FLOW_TESTNET" | "GOERLI" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MONAD_TESTNET" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "RONIN" | "SAIGON_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SHAPE" | "SOLANA" | "SOLDEV" | "SONEIUM" | "SONEIUM_MINATO" | "UNICHAIN" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AssetSearchList_data$data = ReadonlyArray<{
  readonly __typename: string;
  readonly bundleCollection?: {
    readonly isVerified: boolean;
    readonly relayId: string;
  } | null;
  readonly chain: {
    readonly identifier: ChainIdentifier;
  };
  readonly collection?: {
    readonly isVerified: boolean;
    readonly relayId: string;
  };
  readonly isErc721cFeeEnforced?: boolean;
  readonly relayId: string;
  readonly " $fragmentSpreads": FragmentRefs<"ItemCard_data" | "useAssetSelectionStorage_item">;
  readonly " $fragmentType": "AssetSearchList_data";
}>;
export type AssetSearchList_data$key = ReadonlyArray<{
  readonly " $data"?: AssetSearchList_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssetSearchList_data">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v2 = {
  "kind": "Variable",
  "name": "identity",
  "variableName": "identity"
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isVerified",
    "storageKey": null
  },
  (v1/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isErc721cFeeEnforced",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "identifier",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "ChainType",
  "kind": "LinkedField",
  "name": "chain",
  "plural": false,
  "selections": [
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = [
  (v1/*: any*/)
],
v8 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "address",
    "storageKey": null
  }
],
v9 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "AccountType",
    "kind": "LinkedField",
    "name": "maker",
    "plural": false,
    "selections": (v8/*: any*/),
    "storageKey": null
  }
],
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "OrderV2Type",
  "kind": "LinkedField",
  "name": "bestAskV2",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v11 = {
  "kind": "InlineDataFragmentSpread",
  "name": "asset_url",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AssetContractType",
      "kind": "LinkedField",
      "name": "assetContract",
      "plural": false,
      "selections": (v8/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tokenId",
      "storageKey": null
    },
    (v6/*: any*/)
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "identity"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "shouldShowBestBid"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showBestAskForOwner"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showContextMenu"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showQuantity"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "AssetSearchList_data",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "args": [
        (v2/*: any*/),
        {
          "kind": "Variable",
          "name": "shouldShowBestBid",
          "variableName": "shouldShowBestBid"
        },
        {
          "kind": "Variable",
          "name": "showBestAskForOwner",
          "variableName": "showBestAskForOwner"
        },
        {
          "kind": "Variable",
          "name": "showContextMenu",
          "variableName": "showContextMenu"
        },
        {
          "kind": "Variable",
          "name": "showQuantity",
          "variableName": "showQuantity"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ItemCard_data"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CollectionType",
          "kind": "LinkedField",
          "name": "collection",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        (v4/*: any*/)
      ],
      "type": "AssetType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "bundleCollection",
          "args": null,
          "concreteType": "CollectionType",
          "kind": "LinkedField",
          "name": "collection",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "type": "AssetBundleType",
      "abstractKey": null
    },
    (v6/*: any*/),
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useAssetSelectionStorage_item",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ChainType",
          "kind": "LinkedField",
          "name": "chain",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isTradingEnabled",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v4/*: any*/),
            {
              "condition": "withBestBid",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrderV2Type",
                  "kind": "LinkedField",
                  "name": "bestAllTypeBid",
                  "plural": false,
                  "selections": (v7/*: any*/),
                  "storageKey": null
                }
              ]
            },
            {
              "condition": "withBestAsk",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ESOrderDataType",
                  "kind": "LinkedField",
                  "name": "orderData",
                  "plural": false,
                  "selections": [
                    (v10/*: any*/),
                    {
                      "alias": "bestAskForOwner",
                      "args": [
                        {
                          "kind": "Variable",
                          "name": "byAddress",
                          "variableName": "identity"
                        }
                      ],
                      "concreteType": "OrderV2Type",
                      "kind": "LinkedField",
                      "name": "bestAskV2",
                      "plural": false,
                      "selections": (v9/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ]
            },
            (v11/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isCompromised",
              "storageKey": null
            }
          ],
          "type": "AssetType",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ESOrderDataType",
              "kind": "LinkedField",
              "name": "orderData",
              "plural": false,
              "selections": [
                {
                  "condition": "withBestAsk",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    (v10/*: any*/)
                  ]
                },
                {
                  "condition": "withBestBid",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "OrderV2Type",
                      "kind": "LinkedField",
                      "name": "bestBidV2",
                      "plural": false,
                      "selections": (v7/*: any*/),
                      "storageKey": null
                    }
                  ]
                }
              ],
              "storageKey": null
            }
          ],
          "type": "AssetBundleType",
          "abstractKey": null
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "item_sellUrl",
          "selections": [
            (v0/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                (v11/*: any*/)
              ],
              "type": "AssetType",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "slug",
                  "storageKey": null
                },
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "first",
                      "value": 18
                    }
                  ],
                  "concreteType": "AssetQuantityTypeConnection",
                  "kind": "LinkedField",
                  "name": "assetQuantities",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AssetQuantityTypeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AssetQuantityType",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "AssetType",
                              "kind": "LinkedField",
                              "name": "asset",
                              "plural": false,
                              "selections": (v7/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "assetQuantities(first:18)"
                }
              ],
              "type": "AssetBundleType",
              "abstractKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AssetContextMenu_data"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CancelItemOrdersButton_items"
        }
      ],
      "args": [
        (v2/*: any*/),
        {
          "kind": "Variable",
          "name": "withBestAsk",
          "variableName": "showBestAskForOwner"
        },
        {
          "kind": "Variable",
          "name": "withBestBid",
          "variableName": "shouldShowBestBid"
        }
      ],
      "argumentDefinitions": [
        {
          "defaultValue": null,
          "kind": "LocalArgument",
          "name": "identity"
        },
        {
          "defaultValue": false,
          "kind": "LocalArgument",
          "name": "withBestAsk"
        },
        {
          "defaultValue": false,
          "kind": "LocalArgument",
          "name": "withBestBid"
        }
      ]
    }
  ],
  "type": "ItemType",
  "abstractKey": "__isItemType"
};
})();

(node as any).hash = "7b4180d57d73eb359827a9a5037def22";

export default node;
